import {assert} from '@pexip/utils';

import type {
    MediaDeviceInfoLike,
    IndexedDevices,
    IndexedDeviceMap,
} from './types';
import {MediaDeviceKinds} from './types';
import {DEVICE_ID_SEPARATOR} from './constants';

export const isSameDeviceKind =
    (kind: MediaDeviceKinds) => (device: MediaDeviceInfoLike) =>
        device.kind === kind;

export const isAudioInput = isSameDeviceKind(MediaDeviceKinds.AUDIOINPUT);

export const isVideoInput = isSameDeviceKind(MediaDeviceKinds.VIDEOINPUT);

export const isAudioOutput = isSameDeviceKind(MediaDeviceKinds.AUDIOOUTPUT);

/**
 * Check if provided device is a permission-granted device by inspecting the
 * device label
 *
 * @param device - The device to check
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/label#value}
 **/
export const isDeviceGranted = (device: MediaDeviceInfoLike) =>
    Boolean(device.label);

export const not =
    <T = void>(fn: (arg: T) => boolean) =>
    (arg: T) =>
        !fn(arg);

/**
 * Create a device ID from provided `device`.
 * The device ID here is in the format of `groupId:deviceId:kind`, be default.
 *
 * @param device - The device to create the ID from
 * @param separator - The separator to use to join the device ID
 */
export const getDeviceId = (
    device: Pick<MediaDeviceInfoLike, 'groupId' | 'deviceId' | 'kind'>,
    separator: string = DEVICE_ID_SEPARATOR,
) => [device.groupId, device.deviceId, device.kind].join(separator);

/**
 * Reverse the device ID to its original form i.e. `MediaDeviceInfo`
 * The device ID here is in the format of `groupId:deviceId:kind`, be default.
 *
 * @param id - The device ID to reverse
 * @param separator - The separator used to split the device ID
 */
export const reverseDeviceId = (
    id: string,
    separator: string = DEVICE_ID_SEPARATOR,
) => {
    const [groupId, deviceId, kind] = id.split(separator);
    assert(groupId !== undefined, `Invalid id[${id}] format: groupId`);
    assert(deviceId !== undefined, `Invalid id[${id}] format: deviceId`);
    assert(
        kind === 'audioinput' ||
            kind === 'videoinput' ||
            kind === 'audiooutput',
        `Invalid id[${id}] format: kind`,
    );
    return {deviceId, groupId, kind: kind as MediaDeviceKind};
};

type DeviceIndex = IndexedDevices & IndexedDeviceMap;
/**
 * Create an `IndexedDevices` construct from provided `devices` which index the
 * device by device ID or kind.
 */
export const createIndexedDevices = (
    devices: MediaDeviceInfoLike[],
): IndexedDevices => {
    const emptyIndex: DeviceIndex = {
        audioinput: {
            deviceId: new Map(),
            label: new Map(),
            get size() {
                return this.deviceId.size;
            },
        },
        audiooutput: {
            deviceId: new Map(),
            label: new Map(),
            get size() {
                return this.deviceId.size;
            },
        },
        videoinput: {
            deviceId: new Map(),
            label: new Map(),
            get size() {
                return this.deviceId.size;
            },
        },
        size(kind) {
            switch (kind) {
                case 'audioinput':
                    return this.audioinput.size;
                case 'audiooutput':
                    return this.audiooutput.size;
                case 'videoinput':
                    return this.videoinput.size;
                default:
                    return (
                        this.audioinput.size +
                        this.audiooutput.size +
                        this.videoinput.size
                    );
            }
        },
        get(deviceToFind) {
            const {deviceId, kind, label} = deviceToFind ?? {};
            if (!kind) {
                return [
                    ...this.get({...deviceToFind, kind: 'audioinput'}),
                    ...this.get({...deviceToFind, kind: 'audiooutput'}),
                    ...this.get({...deviceToFind, kind: 'videoinput'}),
                ];
            }
            const deviceIdMap = this[kind].deviceId;
            const findSimilarDevices = (label: string) => {
                const foundSimilar = this[kind].label.get(label);
                return foundSimilar ? foundSimilar : [];
            };
            if (deviceId !== undefined) {
                const foundExact = deviceIdMap.get(deviceId);
                if (!foundExact && label) {
                    return findSimilarDevices(label);
                }
                return foundExact ? [foundExact] : [];
            }
            if (label) {
                return findSimilarDevices(label);
            }
            return [...deviceIdMap.values()];
        },
        first(kind) {
            if (!kind) {
                return;
            }
            for (const device of this[kind].deviceId.values()) {
                return device;
            }
        },
        toJSON() {
            return {
                audioinput: this.get({kind: 'audioinput'}),
                videoinput: this.get({kind: 'videoinput'}),
                audiooutput: this.get({kind: 'audiooutput'}),
            };
        },
    };
    return devices.reduce<DeviceIndex>((acc, device) => {
        // We don't want to index devices that are not granted
        if (device.label && device.deviceId !== undefined) {
            acc[device.kind].deviceId.set(device.deviceId, device);
        }
        if (device.label) {
            const labelDevices = acc[device.kind].label.get(device.label) ?? [];
            labelDevices.push(device);
            acc[device.kind].label.set(device.label, labelDevices);
        }
        return acc;
    }, emptyIndex);
};

/**
 * Compare 2 devices to see if they are the same by `deviceId` or `label` as
 * a fallback
 *
 * @beta
 */
export const compareDevices =
    (
        deviceInfo: MediaDeviceInfoLike,
        key: Exclude<
            keyof MediaDeviceInfoLike,
            'kind' | 'settings'
        > = 'deviceId',
    ) =>
    (anotherDeviceInfo: MediaDeviceInfoLike) =>
        deviceInfo.kind === anotherDeviceInfo.kind &&
        deviceInfo[key] === anotherDeviceInfo[key];

/**
 * Lookup the `device` from `devices` list
 *
 * @remarks
 * When we cannot find the device by `deviceId`, we compare the label as last
 * resort, and return the first one.
 *
 * @param deviceToFind - Provide a device info to be used for the searching
 * @param useFallback - Whether to use `label` as a fallback when there is no
 * match from using `deviceId`
 *
 * @beta
 */
export const findDevice =
    (deviceToFind: MediaDeviceInfoLike, useFallback = true) =>
    (devices: readonly MediaDeviceInfoLike[]) => {
        const compareIDTo = compareDevices(deviceToFind);
        const found = devices.find((device: MediaDeviceInfoLike) =>
            compareIDTo(device),
        );

        if (found || !useFallback) {
            return found;
        }

        // Fallback to use label for the comparison
        // and return the first matched, otherwise `undefined`
        const compareLabelTo = compareDevices(deviceToFind, 'label');
        return devices.find((device: MediaDeviceInfoLike) =>
            compareLabelTo(device),
        );
    };
